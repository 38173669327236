@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-Regular.ttf')  format('truetype') /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Nunito-Bold';
  src: url('../assets/fonts/Nunito-Bold.ttf')  format('truetype') /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Nunito-Light';
  src: url('../assets/fonts/Nunito-Light.ttf')  format('truetype') /* Safari, Android, iOS */
}

*{
  font-family: 'Nunito';
  font-size:11pt
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5
}
.form-control{
  color:black!important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    color:#464646!important
}
.Green{
  color:#3c5922
}
label{
  font-size:10.5pt;
  /*color:#3c5922*/
}
input{
  font-size:10pt
}
textarea{
  font-size:10pt
}
textarea::placeholder{
  font-size:10pt
}
input::placeholder{
  font-size:10pt
}
a,a:hover{
  text-decoration: none!important;
}
a:hover{
  color: inherit!important;
}
.btn-link:focus{
  text-decoration:none!important;
  box-shadow: none!important;
}
.invalid-feedback{
  font-size:9pt!important;
  margin-top: 0!important;
}
.MuiAlert-message{
  font-size:10.5pt!important;
}
.Header-Link{
  font-family: 'Nunito';
  color: #3c497c!important;
  font-size:10.4pt;
}
.Header-Link:hover{
  color: #676f93!important;
}
.Header-Logo{
  color: #6c6c6c!important;
  letter-spacing: 0.2rem;
  font-family:"Nunito-Bold";
  font-size:10.3pt;
}
.Header-Logo:hover{
  color: #9f9f9f
}
.navbar-nav li{
  border-right: 1px solid #d5d5d5
}
.navbar-nav li:last-child{
  border-right: none
}

.navbar{
  padding-top:1.4rem!important;
  padding-bottom:1.4rem!important;
}
.Login-Card{
  width:95%;
  margin-right:auto;
  margin-left:auto;
  padding:2rem 5rem!important;
}
.Login-Btn{
  background-color:blue;
  color:white!important;
  font-family: 'Nunito-Bold';
  border-radius:.2rem;
}
.Login-Btn:hover{
  color:#f6f6f6!important;
}
.modal-content{
  border-radius:.6rem!important;
}
.Login-Modal-Footer{
  border-right:1px solid grey;
  padding-right:7px
}
.Spinner-Container{
  top:0;
  left:0;
  right:0;
  bottom:0;
  display:flex;
  position:fixed;
  align-items:center;
  justify-content:center;
  background-color: rgba(107, 107, 107, 0.3);
  z-index: 1056;
  height:100%
}
.Card-Lift{
  transition: .2s;
  background-color: #2ec8d5;
}
.Card-Lift:hover{
  height: 138px!important;
  background-color: rgb(132, 144, 145);
  transition: .2s;
}


@media only screen and (max-width: 600px) {
  .Header-Link{
    font-size:9pt;
  }
  .nav-link{
    font-size:10pt
  }
}
@media only screen and (min-width: 768px) {
  .Header{
    padding-top:1.2rem;
  }
  .Login-Card{
    width:auto
  }
  .navbar-expand-md .navbar-nav .nav-link{
    padding-left: 1.4rem!important;
    padding-right: 1.4rem!important;
    padding-top: 0.2rem!important;
    padding-bottom: 0.2rem!important;
  }
}
